import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Software development is one of the most requested skills nowadays.
It is a great time to be a software developer.`}</p>
    <p>{`Often, I get the question: `}</p>
    <p>{`“`}<strong parentName="p">{`Can I get a job as a software developer without a degree?`}</strong>{`”`}</p>
    <p>{`The short answer is `}<strong parentName="p">{`YES`}</strong>{`.`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/WcBmoUH8Ag8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`You might be a dropout, a person starting to code or maybe, you are a parent with kids and a full-time job.`}</p>
    <p>{`Meanwhile, you are learning to code, building your own webpage, coding an app and you enjoy this side project a lot.`}</p>
    <p>{`What if you could do that as a primary job? But wait…`}<strong parentName="p"><em parentName="strong">{`you don’t have a degree`}</em></strong>{`.`}</p>
    <p><strong parentName="p">{`How can you get a job as a software engineer without a degree?`}</strong></p>
    <p>{`It feels like a deal-breaker and you might abandon your dream for a new career, but not so fast.
That is not the case anymore today.`}</p>
    <p>{`At least, not all the company requires a degree today for a job a software developer.`}</p>
    <p>{`There was news from August 2018 that even a big company like Google, Microsoft, Amazon are removing that strong constraint from applying to these companies.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“…a list of 15 different companies that don’t require job applicants
to have college degrees. The list includes high-paying tech outlets
like Apple, Google, and IBM, in addition to service-oriented companies
like Costco, Starbucks, and Chipotle.”`}</p>
    </blockquote>
    <h6>{`You can read the full article in QZ: `}<a parentName="h6" {...{
        "href": "https://qz.com/work/1367191/apple-ibm-and-google-dont-require-a-college-degree"
      }}>{`"Apple, IBM, and Google don’t care anymore if you went to college"`}</a></h6>
    <p>{`There are a few steps that you can take to make yourself more ‘`}<em parentName="p">{`employable`}</em>{`’ in software development.`}</p>
    <h1>{`KEEP LEARNING`}</h1>
    <p>{`The first priority should be to keep learning and improving your software development skills.`}</p>
    <p>{`There are so many online resources where you can improve your software skills.`}</p>
    <p>{`The first resource that I would recommend for learning software skills is `}<a parentName="p" {...{
        "href": "https://www.codecademy.com/"
      }}>{`Codecademy`}</a>{`.`}</p>
    <p>{`You will find courses and resources for learning all the main programming languages: Javascript, Python, C++, HTML+CSS, and so on.
Other resources could be `}<a parentName="p" {...{
        "href": "https://www.udemy.com/"
      }}>{`Udemy`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.coursera.org/"
      }}>{`Coursera`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.khanacademy.org/"
      }}>{`Khan Academy`}</a>{`.`}</p>
    <p>{`Special mention to a website called `}<a parentName="p" {...{
        "href": "https://my.safaribooksonline.com/"
      }}>{`Safari Book Online`}</a>{`. I use this site all the time because for a small subscription I can get access to so many books and video courses!`}</p>
    <p>{`I read lots of books, not technical ones. For tech books, I use this service.`}</p>
    <p>{`There is an unlimited amount of online resources if you want to learn to code. Python, C++, Javascript, whatever you are interested in.`}</p>
    <h3>{`WANT TO START LEARNING TODAY?`}</h3>
    <h5>{`Codeacademy`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.codecademy.com/catalog/language/html-css"
        }}>{`HTML & CSS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.codecademy.com/catalog/language/javascript"
        }}>{`Javascript`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.codecademy.com/catalog/language/python"
        }}>{`Python`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.codecademy.com/catalog/language/c-plus-plus"
        }}>{`C++`}</a></li>
    </ul>
    <h5>{`Udemy`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udemy.com/courses/search/?ref=home&src=ukw&q=javascript"
        }}>{`Javascript Courses in Udemy`}</a></li>
    </ul>
    <h5>{`Safari Book Online`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learning.oreilly.com/search/?query=javascript&extended_publisher_data=true&highlight=true&include_assessments=false&include_case_studies=true&include_courses=true&include_orioles=true&include_playlists=true&is_academic_institution_account=false&languages=en&sort=relevance"
        }}>{`Javascript Courses in Safari Book Online`}</a></li>
    </ul>
    <h5>{`Coursera`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.coursera.org/courses?query=programming&"
        }}>{`Programming Courses`}</a></li>
    </ul>
    <h1>{`PORTFOLIO`}</h1>
    <p>{`What else can you do to be more appealing to a future employer?`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/11934/0*Gw3mODdlkA2PotGc",
        "alt": "Portfolio image"
      }}></img></p>
    <p>{`A portfolio with all your personal projects could be a good idea.
Create a portfolio page with tools like `}<a parentName="p" {...{
        "href": "https://webflow.com/"
      }}>{`Webflow`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.squarespace.com/"
      }}>{`Squarespace`}</a>{` or just built with scratch using technologies that you are eager to learn.`}</p>
    <p>{`It is important that you show what you can do.`}</p>
    <p>{`Share the code of your projects on sites like `}<a parentName="p" {...{
        "href": "https://github.com/"
      }}>{`GitHub`}</a>{` and `}<a parentName="p" {...{
        "href": "https://codepen.io/"
      }}>{`CodePen`}</a>{`.`}</p>
    <p>{`It will be very useful for employers to check how you write code, tests and how you structure projects.`}</p>
    <p>{`Showing your code can be an advantage for you because it could be a topic of discussion during your job interview. It is much easier to talk about the code that you wrote in technical interviews.`}</p>
    <p>{`Sites that you can use to share your code (and code snippets):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/"
        }}>{`GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codepen.io/"
        }}>{`CodePen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codesandbox.io/"
        }}>{`CodeSandbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://repl.it/"
        }}>{`Repl.it`}</a></li>
    </ul>
    <p>{`Sites for building your portfolio page:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.squarespace.com/"
        }}>{`Squarespace`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webflow.com/"
        }}>{`Webflow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.wix.com/"
        }}>{`Wix`}</a></li>
    </ul>
    <h1>{`NETWORKING`}</h1>
    <p>{`The last but not least suggestion that I can give you is…go to networking events.`}</p>
    <p>{`It has been very important for my career while living abroad.
Mingling at networking events will help to meet the local community of developers and people interested in tech.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/10872/0*2VbgxUFsjWSdn-DR",
        "alt": "Networking"
      }}></img></p>
    <p>{`Check for some meetups about programming languages that you are interested in in your city or nearby.`}</p>
    <p>{`You can also go to hackathons and meet other software developers in your local community.`}</p>
    <p>{`It is time well spent, working with like-minded people while you are growing your technical and soft skills.`}</p>
    <h3>{`WHERE CAN YOU FIND THE NEXT MEETUP?`}</h3>
    <p>{`If you want to find relevant meetups, go to `}<a parentName="p" {...{
        "href": "https://www.meetup.com/"
      }}>{`Meetup.com`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.facebook.com/"
      }}>{`Facebook`}</a>{` and look for relevant programming languages.`}</p>
    <h1>{`BONUS TIP: REJECTION`}</h1>
    <p>{`Remember that there is a chance that you will get rejected by some employer. That’s part of the process in your job hunt.`}</p>
    <p>{`Do not stress if employers will reject you.`}</p>
    <p>{`That’s not about you; it was not meant to be and probably, there wasn’t a match between you and employer at this moment in time.`}</p>
    <p>{`Just keep searching and applying for jobs, you will find the employer that will believe in you.`}</p>
    <h1>{`YOU CAN BE A SOFTWARE DEVELOPER`}</h1>
    <p>{`Today is possible to get a job as software development without a computer degree.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/6912/0*R5aIbONTZNADpb_r",
        "alt": "Developer coding"
      }}></img></p>
    <p>{`Your next employer wants to see results and people that can deliver on their software tasks. It is important that you `}<strong parentName="p">{`keep learning`}</strong>{` and practicing your software skills.`}</p>
    <p>{`That’s why having a `}<strong parentName="p">{`portfolio`}</strong>{` that can show what you can do is relevant before and during your interview process.`}</p>
    <p>{`Last but not least, `}<strong parentName="p">{`networking`}</strong>{` will help you to get known in the local community: find new possible co-workers and employers.`}</p>
    <p>{`You can start thinking about your career path in software development without a degree right now.`}</p>
    <p>{`You can make it today. `}<em parentName="p"><strong parentName="em">{`Be patient and keep pushing forward.`}</strong></em></p>
    <p>{`Well, the last thing: If you arrived here, thank you so much for your support and time that you spend on this page.`}</p>
    <p><strong parentName="p">{`What are your suggestions to get a programming job without a degree?`}</strong></p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/WcBmoUH8Ag8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <h4>{`Have you enjoyed this article, leave a comment and let me know what you think on this topic!`}</h4>
    <h5>{`AUTHOR`}</h5>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h5>{`FOLLOW ME`}</h5>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <p><strong parentName="p">{`Youtube:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Domenico Solazzo's Channel`}</a>{`
`}<strong parentName="p">{`Instagram`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/domenicosolazzo/"
      }}>{`domenicosolazzo`}</a>{`
`}<strong parentName="p">{`Linkedin`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/solazzo/"
      }}>{`solazzo`}</a>{`
`}<strong parentName="p">{`Medium`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://medium.com/@domenicosolazzo"
      }}>{`domenicosolazzo`}</a>{`
`}<strong parentName="p">{`Facebook`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/domenicosolazzo.labs/"
      }}>{`domenicosolazzo`}</a>{`
`}<strong parentName="p">{`Twitter`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a>{`
`}<strong parentName="p">{`Snapchat`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a>{`
`}<strong parentName="p">{`Github`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://github.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a>{`
`}<strong parentName="p">{`Website`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://www.domenicosolazzo.com"
      }}>{`https://www.domenicosolazzo.com`}</a>{`
`}<strong parentName="p">{`Hashnode`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://hashnode.com/@domenicosolazzo"
      }}>{`https://hashnode.com/@domenicosolazzo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      